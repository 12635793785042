.Projects-wrap {
  background-color: #5656560f;
}

.card-img-top5 {
  width: 300px;
  height: 240px;
  border-radius: 10px;
}

.card4 {
  width: 300px;
}

.Projects-grid {
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-direction: row;
  column-gap: 8%;

  padding-bottom: 1rem;

}

.card4 .card-body .card-text {
  color: #555555;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
}

.show-more-container {
  display: flex;
  justify-content: flex-end; /* Aligns the button to the right */
  padding-bottom: 2rem;
}

.show-more-btn {
  padding: 10px 20px;
  background-color:#00C9FF;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 10px;
  font-weight: 700;
}

.show-more-btn:hover {
  background-color: #0056b3;
}



@media (max-width: 768px) {
  .Projects-grid {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    column-gap: 8%;
    justify-content: center;
    align-items: center;
  }

  .card4 .card-body .card-text {
    color: #555555;
    font-size: 18px;
    font-weight: 600;
  }

  .card-img-top5 {
    width: 100% !important;
    height: 267px !important;
    clear: both;
  }

  .card4 {
    width: 360px;
    justify-content: center;
    align-items: center;
    clear: both;
    display: flex;
    flex-direction: column;
  }


}
@media (min-width: 600px) and (max-width: 1024px) {
  .Projects-grid {
    padding-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 3%;
    justify-content: center;
    align-items: center;
  }


  .card-img-top5 {
    width: 100% !important;
    height: 300px !important;
    clear: both;
  }

  .card4 {
    height: 100%;
  }
  
}
