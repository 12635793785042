.navbar-nav {
  margin-left: 1rem;
}

.logoimg {
  width: 227px;
  height: 65.35px;
}

.navbar {
  height: auto;
}

.nav-item {
  font-size: 20px;
  margin-left: 7rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  text-align: center;
  color: #000000;
}

.nav-item a {
  color: black !important ;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.navbar-wrapper {
  /* background-image: url('../Assets/Rectangle\ 3.png'); */
  background-size: cover;
  border-bottom: 2px solid #E8E8E8	;

}

.navbar-brand {
  color: white;
  font-size: 22px;
}

.signup-btn {
  border: 0px;
  background-color: #00C9FF;
  width: 180px;
  height: 51px;
  color: white;
  border-radius: 8px;
  font: 21px;
}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.contaqa-logo{

  width: 180px;
  height: 30px;
}

.navbar-nav .nav-item {
  position: relative;
}

.navbar-nav .nav-item:hover .nav-link {
  color: #02A2E7 !important; /* Change this to your desired hover text color */
}

.navbar-nav .nav-link.active {
  color: #fff; /* Change this to your desired active text color */
}


@media (max-width: 480px) {
  .navbar-nav {
    margin-left: 0%;
  }

  .nav-item a {
    clear: both;
    margin-left: -3rem;
    
  }

  .contaqa-logo{

    width: 140px;
    height: 25px;
  }

  .signup-btn {
    border: 0px;
    background-color: #00C9FF;
    height: 40px;
    color: white;
    font-weight: 700;
    border-radius: 10px;
    width: 100%;
    margin-left: -3rem;
    font-size: 22px;
  }

  #signup-holder {
    justify-content: safe;
    align-items: center;
    text-align: center;
    clear: both;
    width: 100%;
  }

  .nav-item {
    font-size: 20px;
    margin-left: 3rem !important;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    text-align: left;
    color: #000000;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .navbar-nav {
    margin-left: 0rem;
  }
  .signup-btn{
      border: 0px;
      background-color: #00C9FF;
      height: 40px;
      color: white;
      font-weight: 700;
      border-radius: 10px;
      width: 100%;
      font-size: 22px;
  
  }

  .nav-item {
    font-size: 20px;
    margin-left: 4rem;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    text-align: center;
    color: #000000;
  }
} 

