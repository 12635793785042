.grid-services-003{

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 10%;
    padding-bottom: 3rem;
    padding-top: 2rem;
}

.img-for-sigle-service{

    width: 341.99px;
    height: 200.81px;
    
    
}



@media (max-width: 768px) {

    .grid-services-003{

        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        padding-bottom: 0rem;
    }
    
}

@media (min-width: 600px) and (max-width: 1024px) {


    .grid-services-003{

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 2rem;
        padding-bottom: 0rem;
        column-gap: 2rem;
    }

    .img-for-sigle-service{

        width: 100%;
        height: 100%;
        
        
    }

}