.card3 {
  position: relative;
  width: 300px; /* Adjust as needed */
  height: 400px; /* Adjust as needed */
}

.image-full {
  width: 330px;
  height: 220px;
  padding: 0.5rem;
  position: absolute;
  left: 8%;
  margin-top: -3rem;
}

.image-overlay {
  width: 300px;
  height: 290px; /* Only show 30% of the second image */
  border-radius: 15px !important;
}

.image-partial {
  width: 100%;
  height: 295px;
  border-radius: 10px;
  object-fit: cover;
}

.text-003{
  font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #7D7D7D;

}

.overlay-text {
  position: absolute;
  bottom: 200px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  color: white; /* Adjust as needed */
  font-size: 1.5rem; /* Adjust as needed */
  z-index: 1;
  font-size: 14px;
  font-weight: 700;

}

.overlay-paragraph{

  position: absolute;
  bottom: 140px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  color: white; /* Adjust as needed */
  font-size: 1.5rem; /* Adjust as needed */
  z-index: 1;
  font-size: 14px;
  font-weight: 700;
}

.overlay-paragraph2{

  position: absolute;
  bottom: 120px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  color: white; /* Adjust as needed */
  font-size: 1.5rem; /* Adjust as needed */
  z-index: 1;
  font-size: 14px;
  font-weight: 700;
}

.overlay-paragraph3{

  position: absolute;
  bottom: 100px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  color: white; /* Adjust as needed */
  font-size: 1.5rem; /* Adjust as needed */
  z-index: 1;
  font-size: 14px;
  font-weight: 700;
}

.cards-holder {
  padding-top: 4.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10%;
}

.Service-wrap{
  background-color: #F5F5F5;
}

@media (max-width: 768px) {

  .text-5{

    font-size: 24px;
    width: 100%;
    color: #000000;
    font-weight: 700;
  }

  .text-003{
    font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: #7D7D7D;
  
  }
  

  .cards-holder {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5rem !important;
    padding-bottom: 5rem;
  }

  .image-full {
    width: 100%;
    height: 240px;
    position: absolute;
    left: 2%;
    margin-top: -11px;
  }
  
  .image-overlay {
    width: 100%;
    height: 290px; /* Only show 30% of the second image */
    border-radius: 15px !important;
  }
  .overlay-text {
    position: absolute;
    bottom: 50px; /* Adjust as needed */
    left: 10px; /* Adjust as needed */
    color: white; /* Adjust as needed */
    font-size: 1.5rem; /* Adjust as needed */
    z-index: 1;
    font-size: 18px;
    font-weight: 700;
  }
  .card3 {
    position: relative;
    width: 300px; /* Adjust as needed */
    height: 300px; /* Adjust as needed */
  }
  .image-partial {
    width: 100%;
    height: 350px;
    border-radius: 10px;
    object-fit: cover;
  }

  .overlay-paragraph{

    position: absolute;
    bottom: -10px; /* Adjust as needed */
    left: 10px; /* Adjust as needed */
    color: white; /* Adjust as needed */
    font-size: 1.5rem; /* Adjust as needed */
    z-index: 1;
    font-size: 14px;
    font-weight: 700;
  }
  
  .overlay-paragraph2{
  
    position: absolute;
    bottom: -30px; /* Adjust as needed */
    left: 10px; /* Adjust as needed */
    color: white; /* Adjust as needed */
    font-size: 1.5rem; /* Adjust as needed */
    z-index: 1;
    font-size: 14px;
    font-weight: 700;
  }
  
  .overlay-paragraph3{
  
    position: absolute;
    bottom: -50px; /* Adjust as needed */
    left: 10px; /* Adjust as needed */
    color: white; /* Adjust as needed */
    font-size: 1.5rem; /* Adjust as needed */
    z-index: 1;
    font-size: 14px;
    font-weight: 700;
  }
}
@media (min-width: 600px) and (max-width: 1024px) {
  .text-5{

    font-size: 20px;
    color: #000000;
    font-weight: 700;
  }

  .cards-holder {
    padding-top: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 18%;
    padding-bottom: 2rem;
    overflow-y: hidden;
  }

}