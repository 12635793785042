.Partner-container {
    padding-top: 3rem;
    padding-bottom: 3rem;

}

.Partner-Wrap {
    background: #F5F5F5;

}

.Partner-title h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 45.4px;
    text-align: center;
    color: #03A8EC;
    font-family: "Poppins", sans-serif;

}

.Partner-title p {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #7D7D7D;


}

.Partner-grid {
    padding-top: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    row-gap: 10%;
}

.grid-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.5rem;
    margin-top: 2.5rem;

}





.Partner-grid-element {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15.625rem;
    height: 6.875rem;
    background-color: white;
    border: 1px solid #E8E8E8;
    border-radius: 20px;
}

.partner-img-auto {

    width: auto;

}

@media (max-width: 768px) {


    .partner-img-auto {

        padding:10px !important;
    
    }

    .Partner-grid {
        padding-top: 1.5rem;
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: 1fr 1fr ;
        row-gap: 7%;
        column-gap: 5%;
        clear: both;
    }

    .Partner-grid-element {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 150px;
        width: 100%;
        padding: 0rem;
        height: 6rem;
        background-color: white;
        border: 1px solid #E8E8E8;
        border-radius: 20px;
    }

    .Partner-title p {
        font-family: "Poppins", sans-serif;
        font-size: 15px;
        font-weight: 400;
        text-align: center;
        color: #7D7D7D;
    
    
    }

    .grid-2 {
        display: grid;
        grid-template-columns: 1fr 1fr ;
        justify-content: center;
        align-items: center;
        column-gap: 5%;
        margin-top: 10.5rem;
        
    }


}

@media (min-width: 600px) and (max-width: 1024px) {
    .partner-img-auto {

        padding:10px !important;
    
    }

    .Partner-grid {
        padding-top: 1.5rem;
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        row-gap: 7%;
        column-gap: 2%;
        clear: both;
    }


    .Partner-grid-element {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 170px;
        padding: 0rem;
        height: 6rem;
        background-color: white;
        border: 1px solid #E8E8E8;
        border-radius: 20px;
    }

    .grid-2 {
        display: flex;
        grid-template-columns: 1fr 1fr ;
        justify-content: center;
        align-items: center;
        column-gap: 2%;
        
    }




}