.wrap {
  /* border-top: 2px solid #E8E8E8		; */
}

.GridWrap {
  display: flex;
  flex-direction: row;
  column-gap: 4%;
}

.left-Banner-sec {
  padding-top: 7rem;
}

.right-Banner-sec {
  padding-top: 0rem;
}

.Banner-header {
  font-size: 30px;
}

.card {
  border: 0px;
  border-bottom: 2px solid #E8E8E8	;
}
.card-title {
  color: #00C9FF;
  justify-content: center;
  text-align: center;
}

.card-text {
  justify-content: center;
  text-align: center;
}

.Banner-paragraph {
  padding-top: 2rem;
  font-size: 18px;
  padding-bottom: 0.5rem;
  color: #7D7D7D;

}

.right-Banner-sec img {
  width:650px
  
}
.card-flex {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10%;
}

@media (max-width: 768px) {
  .right-Banner-sec img {
    width: 226px;
    height: 255px;
  }

  .left-Banner-sec {
    padding-top: 2rem;
  }

  .GridWrap {
    display: flex;
    flex-direction: column-reverse;
  }

  .right-Banner-sec {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Banner-header {
    font-size: 21px;
    justify-content: center;
    text-align: center;
    font-weight: 700;
  }
  .Banner-paragraph {
    padding-top: 0.3rem;
    font-size: 14px;
    padding-bottom: 0.5rem;
    text-align: center;
  }
  .card-flex {

    column-gap: 5%;
    padding-bottom: 2rem;
  }
  .card-title {
font-weight: 600;
  }
  
  .card-text {
    font-weight: 600;

  }

  .card {
   width: auto;
  }
  
}
@media (min-width: 600px) and (max-width: 1024px) {
  
  .right-Banner-sec img {
    width: 280px;
    height: 320px;
  }
  .Banner-header {
    font-size: 24px;
    font-weight: 700;

  }
  .left-Banner-sec {
    padding-top: 4rem;
  }
  .Banner-paragraph {
    padding-top: 0.3rem;
    font-size: 14px;
    padding-bottom: 0.5rem;
    text-align: center;
  }
 
}
