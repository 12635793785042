.titles-mid {
  padding-top: 3rem;
  justify-content: center;
  text-align: center;
}

.About-wrap {
  /* background-color: #5656560f; */
  padding-bottom: 3rem;
}

.About {
  padding-bottom: 0.5rem;
  font-size: 38px;
  color: #03A8EC;
  font-weight: 700;
  font-family: "Poppins", sans-serif;

}

.text-1 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #000000;
  font-weight: 400;
  color: #7D7D7D;
  text-align: center;
}

.text-2 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #000000;
  font-weight: 400;
  color: #7D7D7D;
  text-align: center;
}

.About-layout {
  display: flex;
  flex-direction: row;
  padding-top: 2rem;
}

.About-img img {
  width: 620px;
  height: 421.62px;
}

.Finincial-items {
  display: flex;
  flex-direction: column;
  margin-left: 2%;
  padding-top: 1.5rem;
}

.card2 .card-body {
  padding-top: 5px;
}

.card-title2 {
  font-size: 20px;
}

.card-text2 {
  color: #555555;
  width: 100%;
  font-size: 16px;
  margin-bottom: 0px;
}

.img-fluid002 {
  padding-top: 0.2rem;
  margin-left: 17px;
}

@media (max-width: 768px) {
  .titles-mid {
    padding-top: 2rem;
  }
  .About {
    font-size: 24px;
  }

  .text-1 {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #7D7D7D;

}
  .text-2 {
    font-size: 14px;
  }

  .About-img img {
    width: auto;
    height: 240px;
  }

  .About-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .About-layout {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
  }
  .Finincial-items {
    margin-left: 0%;
  }

  .card-title2 {
    font-size: 18px;
    font-weight: 600;
  }
  .card-text2 {
    
    font-size: 13px;
    font-weight: 500;
    width: auto;

  }
  .img-fluid002 {
    padding-top: 0.1rem;
    margin-left: 1rem;
  }
  .card2 .card-body {
    padding-right: 0px;
  }
}
@media (min-width: 600px) and (max-width: 1024px) {

  .titles-mid {
    padding-top: 2rem;
  }
  .About {
    font-size: 26px;
  }

  .text-1 {
    font-size: 26px;
  }

  .text-2 {
    font-size: 16px;
  }
  .About-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .About-img img {
    width:600px;
    height: 314px;
  }

  .About-layout {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
  }

  .card-title2 {
    font-size: 25px;
    font-weight: 600;
  }
  .card-text2 {
    font-size: 21px;
    font-weight: 500;
    width: auto;
  }
}