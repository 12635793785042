.img-sec{

    justify-content: center;
    text-align: center;
    padding-top: 3rem;
}

.footer-image{

    width: 80px;
    height: 65px;

}

.mid-sec h6{
    padding-top: 1rem;
    font-family: Poppins;
    font-size: 19px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    
}


.footer {


  border: 2px solid #E8E8E8
}
.footer-img{

    width:110px ;
    height: 20px;
}

.footer-line {
    width: 152%;
    border-top: 2px solid #ccc; /* Adjust color as needed */
    margin-top: 10px;
  }
  
  .footer-content {
    margin-top: 10px; /* Adjust as needed */
    color: #333; /* Adjust as needed */
    font-size: 14px; /* Adjust as needed */
  }


  .flexing-footer{
        padding-top: 0.7rem;
        display: flex;
        column-gap: 7%;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-bottom: 1rem;

  }


  .footer-flex-element span{

    font-family: Poppins;
    font-size: 16.26px;
    font-weight: 500;
    line-height: 27.39px;
    color: #000000;


  }

  @media (max-width: 768px) {

    .img-sec{

        padding-top: 1rem;
    }

    .footer-content {
        margin-top: 5px; /* Adjust as needed */
        color: #333; /* Adjust as needed */
        font-size: 14px; /* Adjust as needed */
        padding-bottom: 0.5rem;
      }

      .footer-img{

        width:90px ;
        height: 15px;
    }


    .footer-line {
        width: 132%;
        border-top: 2px solid #ccc; /* Adjust color as needed */
        margin-top: 10px;
        clear: both;
      }

      .mid-sec h6{
        padding-top: 1rem;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 36px;
        text-align: center;
        
    }

    .flexing-footer{
      padding-top: 0.7rem;
      display: flex;
      flex-direction: column;
      column-gap: 7%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-bottom: 1rem;
      row-gap: 0.5rem;
}

  }
