* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

/*   
    body {
      min-height: 100vh;
      width: 100%;
      padding-top: 4rem;
    } */

section {
  margin-top: 0rem;
}

/* .container {
      max-width: 1080px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 20px;
      padding-right: 20px;
    } */

.section-header {
  margin-bottom: 50px;
  text-align: center;
}

.section-header h2 {
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 64px;
  font-weight: 700;
  line-height: 96px;
  text-align: center;

  align-items: center;
}

.section-header p {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  color: #FFFFFF;
  align-items: center;
  width: 700px;


}

.contactrow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.contact-info {
  width: 50%;
}

.contact-info-item {
  display: flex;
  margin-bottom: 30px;
}

.contact-info-icon {
  height: 70px;
  width: 70px;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #d9d9d9;
}

.contact-info-icon i {
  font-size: 30px;
  line-height: 70px;
}

.contact-info-content {
  margin-left: 20px;
}

.contact-info-content h4 {
  color: #2e3758;
  font-size: 1.4em;
  margin-bottom: 5px;
}

.contact-info-content p {
  color: #2e3758;
  font-size: 1em;
}

.contact-form {
  background-color: #fff;
  padding: 1.5rem;
  width: 45%;
  border-radius: 8px;
  box-shadow: 0px 1px 8.6px 2.73px #7270707d;
}

.contact-form h2 {
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 10px;
  color: #02A2E7;
  text-align: center;
}

.contact-form .input-box {
  position: relative;
  width: 100%;
  margin-top: 10px;

}

.contact-form .input-box textarea {
  width: 100%;
  height: 7rem;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 1px solid #D9D9D9DE;
  outline: none;
  resize: none;
}

.contact-form .input-box textarea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.contact-form .input-box textarea::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.contact-form .input-box textarea::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d9d9d9;
}

.contact-form .input-box input {
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 1px solid #D8D8D8;
  outline: none;
  resize: none;
  color: #2e3758;
}

.contact-form .input-box span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #2e3758 !important;
}

.contact-form .input-box input[type="submit"] {
  width: 100%;
  background: #02A2E7;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 10px;
  transition: 0.5s;
}

.contact-form .input-box input[type="submit"]:hover {
  background: #0175ff;
  color: #fff;
}

.input-box-val{
  display: flex;
  justify-content: flex-start;
  text-align: start;
}

@media (max-width: 991px) {
  section {
    padding-top: 0rem;
    padding-bottom: 50px;
    margin-top: 0rem !important;
  }

  .contactrow {
    flex-direction: column;
  }

  .contact-info {
    margin-bottom: 40px;
    width: 100%;
  }

  .contact-form {
    width: 100%;
  }

  section {
    margin-top: 4.5rem;
  }

  .contact-form {
    background-color: #fff;
    padding: 1.5rem;
    width: 90%;
    border-radius: 8px;
    box-shadow: 0px 1px 8.6px 2.73px #7270707d;
  }

  .contactrow {
    margin-bottom: 0rem;
  }
}

.popup {
  position: fixed;
  border: 3px solid #f1f1f1;
  top: 0px;
  left: 0px;
  visibility: hidden;
  /* transform: translate(-50%, -50%) scale(1); */
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.7);
  z-index: 99998;
}

.popup-content .header h5 {
  font-size: 20px;
  font-weight: 700;
  color: #1f3473;
  padding: 10px;
}

.popup .popup-content {
  border-radius: 15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 2;
  padding: 10px;
  box-sizing: border-box;
  width: 25rem;
  height: 10rem;
}

.popup .popup-content .form-element {
  padding: 10px;
  padding-bottom: 7px;
}

.popup .popup-content .form-element label {
  font-size: 18px;
  padding-bottom: 3px;
}

.popup .popup-content .form-element input {
  width: 100%;
  height: 40px;
  padding: 5px;
}

.popup .popup-content .header h2 {
  text-align: center;
  color: #51b1e3;
  padding: 5px;
  font-size: 30px;
}

.close-btn {
  position: absolute;
  right: 20px;
  top: 15px;
  background: none;
  border: none;
  font-size: 25px;
  font-weight: 700;
  color: #02A2E7;
}

.back-btn {
  position: absolute;
  left: 25px;
  top: 7px;
  background: none;
  border: none;
  font-size: 18px;
}

.form-container {
  padding: 10px;
  background-color: white;
}

.open-popup {
  visibility: visible;
}

.check-box-label {
  padding-top: 10px;
  right: 50%;
}


.recaptcha-container {
  display: flex;
  justify-content: flex-start;
  /* Center horizontally */
  margin-top: 1.5rem;
}


.banner-contact-us {
  position: relative;
  background-image: url('/webapps/templete002/src/Assets/Rectangle 196.png');
  /* Replace with your image path */
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 300px;
  /* Adjust height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  /* White text color for better contrast */
}

.wrap-container-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;
}

.contact-form-new {
  width: 1107.7px;
  height: auto;
  background: #FFFFFF;
  box-shadow: 0px -3.12px 8.43px 0px #00000040;
  border-radius: 21px;
  padding: 3rem;
  justify-content: center;
  align-items: center;
  margin-top: -5rem;
  z-index: 3;

}

.flex-1 {
  display: flex;
  column-gap: 15%;
  padding-bottom: 3rem;
}

.input-box .form-control {
  min-width: 430px;
  width: 100% !important;
  height: 87px !important;
  border-radius: 13.53px;
  font-size: 18.82px;

}

.titles-for-contact-us h2 {
  font-family: Poppins;
  font-size: 33.31px;
  font-weight: 600;
  line-height: 49.97px;
  text-align: center;
  color: #000000;
}

.titles-for-contact-us p {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 400;
  line-height: 33px;
  text-align: center;


  color: #000000;

}

.titles-for-contact-us {}

.send-btn-003 {
  width: 180px;
height: 48.69px;
border-radius: 13.53px ;
background: #02A2E7;
font-family: Poppins;
font-size: 20.82px;
font-weight: 400;
line-height: 31.23px;
text-align: center;
color: #FFFFFF;
border: 0px;
margin-top: 2rem;
}


.banner-content5 img{

  width: 100%;
}

.banner-content5 {
  margin-top: -5rem;
}

@media (max-width: 768px) {

  .contact-form-new {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px -3.12px 8.43px 0px #00000040;
    border-radius: 21px;
    padding: 2rem !important;
    justify-content: center;
    align-items: center;
    margin-top: -5rem;
    z-index: 3;
  
  }

  .section-header h2 {
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 700;
    line-height: 96px;
    text-align: center;
  
    align-items: center;
  }
  
  .section-header p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    align-items: center;
    width: auto;
    padding: 1rem;
  
  
  }

  .flex-1 {
    display: flex;
    flex-direction: column;
    column-gap: 5%;
    row-gap:1rem;
    padding-bottom: 1rem;
  }
  
  .input-box .form-control {
    min-width: 100%;
    width: 100% !important;
    height: 87px !important;
    border-radius: 13.53px;
  
  
  }
  .contact-form-new {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px -3.12px 8.43px 0px #00000040;
    border-radius: 21px;
    padding: 3rem;
    justify-content: center;
    align-items: center;
    margin-top: -5rem;
    z-index: 3;
  
  }

  .banner-content5 img{

    width: 100%;
    height:100%;
  }
  
  .banner-content5 {
    margin-top: -7rem;
  }


}

@media (min-width: 600px) and (max-width: 1024px) {
  .flex-1 {
    display: flex;
    column-gap: 19%;
    padding-bottom: 3rem;
  }
  
  .input-box .form-control {
    min-width: 100%;
    width: 100% !important;
    height: 87px !important;
    border-radius: 13.53px;
  
  
  }
  .contact-form-new {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px -3.12px 8.43px 0px #00000040;
    border-radius: 21px;
    padding: 3rem;
    justify-content: center;
    align-items: center;
    margin-top: -5rem;
    z-index: 3;
  
  }
}